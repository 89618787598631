import { graphql, navigate } from 'gatsby';
import React from 'react';
import get from 'lodash/get';
import './blog-section.scss';
import { IBlogPostPreviewNode } from '../queries-intefaces/posts';
import loadable from '@loadable/component';

interface IBlogTags {
  name: string;
  code: string;
}

interface IBlogSectionState {
  blogPosts: Array<IBlogPostPreviewNode>;
  allBlogs: Array<IBlogPostPreviewNode>;
  filterTag: String;
}

class BlogSectionPageTemplate extends React.Component<any, IBlogSectionState> {
  readonly btnStyle = 'text-center buttons-options max-width';
  constructor(props) {
    super(props);
    this.state = {
      blogPosts: get(this, 'props.data.allContentfulBlogPost.nodes'),
      allBlogs: get(this, 'props.data.allContentfulBlogPost.nodes'),
      filterTag: ''
    };
    this.filterBlog = this.filterBlog.bind(this);
  }
  handleClick(slug) {
    navigate(`/blog/${slug}`);
  }
  getFilterTag(){
    return this.state.filterTag;
  }
  filterBlog(tag: string) {
    if(this.getFilterTag() == tag){
      this.setState({ blogPosts: this.state.allBlogs });
      this.setState({ filterTag: '' });
    }
    else{
      let blog = null;
      const blogs = get(this, 'props.data.allContentfulBlogPost.nodes');
      let blogPosts = blogs.filter(item => {
        blog = item.tags.find(e => e.code === tag);
        return blog;
      });
      this.setState({ blogPosts: blogPosts });
      this.setState({ filterTag: tag });
    }
  }

  render() {
    const Layout = loadable(() => import ('../components/layout'));
    const HomePostsSection  = loadable(() => import ('../components/home/home-posts-section/home-posts-section'));

    const blogPosts = get(this, 'props.data.allContentfulBlogPost.nodes');
    const blogSection = get(
      this,
      'props.data.allContentfulBlogSectionPage.nodes'
    );
    const [footerData] = get(this, 'props.data.allContentfulFooterSection.nodes');
    const [headerData] = get(this, 'props.data.allContentfulHeaderSection.nodes');
    const filters = get(this, 'props.data.allContentfulBlogTags.nodes');
    return (
      <Layout className="violet-style" footer={footerData} header={headerData}>
        <div style={{ background: '#fff' }} className="">
          <div className="blog-section">
            <div className="blog-section-header">
              <div className="container">
                <div className="latest-blog">
                  <span className="tag-latest">Latest Blog</span>
                  <h1>{blogPosts[0].title}</h1>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: blogPosts[0].description.childMarkdownRemark.html,
                    }}
                  ></p>
                  <button onClick={() => this.handleClick(blogPosts[0].slug)}>
                    Read Now
                  </button>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column section-title mt-5">
              <div className="container mt-4 text-center">
                <h1>{blogSection[0].title}</h1>
                <p
                  dangerouslySetInnerHTML={{
                    __html: blogSection[0].description.childMarkdownRemark?.html,
                  }}
                ></p>
              </div>
              <div className="container">
                <div className="row justify-content-center">
                  {filters.map((element, key) => {
                    return (
                      <div key={key} className={this.btnStyle} >
                        <button onClick={() => this.filterBlog(element.code)}
                          className={this.getFilterTag() == element.code ? 'selected' : ''}>
                            {element.name}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
              <HomePostsSection posts={this.state.blogPosts} />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default BlogSectionPageTemplate;

export const pageQuery = graphql`
  query BlogSectionQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      nodes {
        title
        slug
        publishDate(formatString: "MMMM Do, YYYY")
        tags {
          code
          name
        }
        heroImage {
          fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
            src
          }
        }
        description {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    allContentfulBlogSectionPage {
      nodes {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    allContentfulBlogTags {
      nodes {
        name
        code
      }
    }
    allContentfulFooterSection(limit: 1){
      nodes {
        icon {
          id
          fluid {
            src
            base64
          }
          file {
            url
          }
        }
        description {
          childMarkdownRemark{
            html
          }
        }
        copyright {
          childMarkdownRemark{
            html
          }
        }
        socialMediaLinks{
          title
          linkUrl
          showLink
        }
        links{
          title
          linkUrl
          showLink
        }
      }
    }
    allContentfulHeaderSection(limit: 1){
      nodes {
        title
        leftLinks{
          title
          linkUrl
          showLink
        }
        rightLinks{
          title
          linkUrl
          showLink
        }
      }
    }
  }
`;
